import {
  Typography,
  Container,
  makeStyles,
  createStyles,
  useMediaQuery,
  useTheme,
  Grid,
} from '@material-ui/core'
import React from 'react'
import Testimonial from './Testimonial'
import SwiperCore, { Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper/types/swiper-options'
import CMSText, { prismicText } from '../Global/CMSText'
SwiperCore.use([Pagination, Scrollbar, A11y])

type Tprops = {
  homePage?: any
  t?: any
}
const useStyles = makeStyles((theme) =>
  createStyles({
    bg: {
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      '& .MuiContainer-root': {
        padding: 0,
      },
      [theme.breakpoints.up('md')]: {
        '&:before': {
          zIndex: 0,
          content: '""',
          position: 'absolute',
          top: '50%',
          left: 0,
          right: 0,
          height: '50%',
          backgroundColor: theme.extraColors.greyLight,
        },
        '& .MuiGrid-root': {
          zIndex: 1,
          position: 'relative',
        },
      },
    },
    alignCenter: {
      textAlign: 'center',
    },
    spacing: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
  })
)

const TestimonialSection = ({ homePage, t }: Tprops) => {
  const classes = useStyles()
  const theme = useTheme()

  const swiperParams: SwiperOptions = {
    pagination: { clickable: true },
    autoHeight: true,
    spaceBetween: theme.spacing(4),
    slidesPerView: 1,
    slidesPerGroup: 1,
  }

  return (
    <section
      className={`section-testimonials section__spacer ${classes.spacing} ${classes.bg}`}
    >
      <Container className={classes.alignCenter}>
        <Typography
          color={'primary'}
          className={`section-testimonials__title ${classes.title}`}
          variant={useMediaQuery(theme.breakpoints.up('md')) ? 'h3' : 'body1'}
          component="h2"
        >
          <CMSText
            asText
            data={homePage?.data?.titletestimonials}
            defaultText={t('texts:homepage:titletestimonials')}
          />
        </Typography>

        {useMediaQuery(theme.breakpoints.up('md')) ? (
          <Grid container spacing={4}>
            {homePage?.data?.testimonial.length > 2
              ? homePage?.data?.testimonial.map((testi) => {
                  return (
                    <Grid
                      item
                      sm={12}
                      md={4}
                      key={`slide-${prismicText(testi?.title)}`}
                    >
                      <Testimonial
                        titleVariant={'h3'}
                        descVariant={'body2'}
                        subtitleVariant={'h4'}
                        title={prismicText(testi?.title)}
                        desc={testi.description}
                        subtitle={prismicText(testi?.subtitle)}
                        titleColor={'primary'}
                        icon={testi.photo.url}
                      />
                    </Grid>
                  )
                })
              : [0, 1, 2].map((key) => {
                  return (
                    <Grid item sm={12} md={4} key={`slide-${key}`}>
                      <Testimonial
                        titleVariant={'h3'}
                        descVariant={'body2'}
                        subtitleVariant={'h4'}
                        title={t('texts:general:add_a_title')}
                        desc={t('texts:general:add_a_description')}
                        subtitle={t('texts:general:add_a_subtitle')}
                        titleColor={'primary'}
                        icon={'/images/placeholder.jpg'}
                      />
                    </Grid>
                  )
                })}
          </Grid>
        ) : (
          <Swiper {...swiperParams}>
            {homePage?.data?.testimonial.map((testi) => {
              return (
                <SwiperSlide key={`slide-${prismicText(testi?.title)}`}>
                  <Testimonial
                    titleVariant={'h3'}
                    descVariant={'body2'}
                    subtitleVariant={'h4'}
                    title={prismicText(testi?.title)}
                    desc={testi.description}
                    subtitle={prismicText(testi?.subtitle)}
                    titleColor={'primary'}
                    icon={testi.photo.url}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </Container>
    </section>
  )
}

export default TestimonialSection
