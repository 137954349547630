import React from 'react'
import { Typography, makeStyles, createStyles } from '@material-ui/core'
import CMSText from '../Global/CMSText'

type Tprops = {
  icon?: string
  title?: string
  desc: []
  titleColor: any
  subtitle: string
  titleVariant: any
  subtitleVariant: any
  descVariant: any
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      background: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      margin: theme.spacing(4, 'auto', 6), // bottom margin push the mobile carousel pagination
      padding: theme.spacing(3),
      borderRadius: theme.shape.borderRadius,
      maxWidth: 290,
      [theme.breakpoints.up('md')]: {
        marginBottom: 2,
      },
    },
    icon: {
      border: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: '50%',
      maxWidth: 150,
      height: 'auto',
    },
    subtitle: {
      fontWeight: 400,
    },
    title: {
      marginTop: theme.spacing(2),
      //color: theme.palette.primary.main,
    },
    desc: {
      color: theme.palette.common.black,
      [theme.breakpoints.up('md')]: {
        minHeight: 84,
      },
    },
  })
)

const Testimonial = (props: Tprops) => {
  const classes = useStyles()

  const { icon, title, subtitle, desc } = props
  return (
    <div className={`testimonial ${classes.root}`}>
      {icon && (
        <img
          loading="lazy"
          src={icon}
          className={`testimonial__icon ${classes.icon}`}
          alt=""
        />
      )}
      {title && (
        <Typography
          variant="h3"
          className={`testimonial__title ${classes.title}`}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          variant="h5"
          component="div"
          className={`testimonial__subtitle ${classes.subtitle}`}
        >
          {subtitle}
        </Typography>
      )}
      {desc && (
        <Typography
          variant="body2"
          component="div"
          className={`testimonial__desc ${classes.desc}`}
        >
          <CMSText data={desc} />
        </Typography>
      )}
    </div>
  )
}

export default Testimonial
